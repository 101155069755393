export const LOGIN_STATUS_CODE = {
    SUCCESSFUL: 200,                      // 성공
    UNAUTHORIZED: 401,                    // 인증실패
    UNAUTHENTICATION: 400,                // 인증정보 없음
    AUTHENTICATION_FAILURE: 410,          // 로그인 실패
    ACCESS_FAILURE: 440,                  // 접근권한 없음
    USER_NOT_USED: 406,                   // 유저 사용 불가
    USER_IS_LOCK: 402,                    // 유저 잠금 상태
    USER_IS_NOT_ALLOWS: 405,              // 유저 사용 허용 불가
    USER_IS_LOCKED: 407,                  // 비밀번호 실패로 인한 잠금 상태
    ERROR_IS_DB_FAILED: 511,              // DB 등록,수정,삭제 중 에러
    ERROR_IS_GYM_CODE_FAILED: 501,        // GYM 코드 유효하지 않음
    ERROR_IS_SERVER_ERROR: 500,           // 서버에러
    ERROR_IS_INPUT_PARAMS_INVALID: 503    // 입력 파라메터 오류
};
