import React, { Component, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

//import Service from "../../modules/_service";

import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';

// routes config
import routes from '../../modules/_routes/routes';

import "./Franchise.css";

const Header = React.lazy(() => import('./main/Header'));
const Footer = React.lazy(() => import('./main/Footer'));

const FrHeader = React.lazy(() => import('./main/FrHeader'));
const FrFooter = React.lazy(() => import('./main/FrFooter'));

const FrSidebar = React.lazy(() => import('./main/FrSidebar'));

class MainView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userInfo: this.props.userInfo,
      period: 1,
      sessionMaxInactiveInterval: 0,
      checkTime: this.props.userInfo.userInfo.sessionIntervalTime,
      nav: JSON.parse(this.props.userInfo.userInfo.nav)
    }
  }

  // componentDidMount() {
  //   Service.http.session.sessionTime = this.state.checkTime;
  //   const form = this;
  //   this.setInterval = window.setInterval(() => {
  //     Service.http.session.sessionTime -= form.state.period;
  //     form.state.checkTime = Service.http.session.sessionTime;
  //     form.setState({
  //       checkTime: form.state.checkTime
  //     })
  //     if (form.state.checkTime === 0) {
  //        alert('세션유지시간이 만료되어 로그아웃을 실행합니다.');
  //        form.props.history.push('/login');
  //     }
  //   }, this.state.period * 1000);
  // }

  componentWillUnmount(){
    clearInterval(this.setInterval);
  }

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>
  signOut(e) {
    e.preventDefault();
    if (window.confirm("로그아웃 하시겠습니까?")) {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.franchise){
        localStorage.removeItem('userInfo');
        this.props.history.push('/fr/login');
        document.body.classList.remove('frSidebarMobile-show');
        document.body.classList.remove('frSidebar-small');
      } else {
        this.props.history.push('/login')
      }
    }
  }

  sidebarBtn = () => {
    document.body.classList.remove('frSidebarMobile-show');
    document.body.classList.remove('frSearchMobile-show');
    // document.querySelector('.frSearchAutoBox').classList.remove('frSearchMobile-show');
    document.querySelector('.frHeaderInfo').classList.remove('frHeaderInfoMobile-show');
    document.body.classList.remove('frHeaderInfoMobile-show');
  }

  render() {
    return (
      this.props.userInfo.userInfo.franchise ?
        <div className="app franchise"> {/* begin of app */}
          <AppHeader fixed>
            <Suspense fallback={this.loading()}>
              <FrHeader onLogout={e=>this.signOut(e)} props={{userInfo: this.state.userInfo}} {...this.props} />
            </Suspense>
          </AppHeader>
          <div className="app-body">
            <Suspense fallback={this.loading()}>
              <FrSidebar
                onLogout={e=>this.signOut(e)}
                navConfig={this.state.nav} 
                {...this.props}
              />
            </Suspense>
            <main className="main">
              <Container fluid>
                <Suspense fallback={this.loading()}>
                  <Switch>
                    {routes.map((route, idx) => {
                      return route.component ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={props => (
                            <route.component {...props} />
                          )} />
                      ) : (null);
                    })}
                    <Redirect from="/" to="dashboard" />
                  </Switch>
                </Suspense>
              </Container>
            </main>
            <div className="frSideDisplay" onClick={this.sidebarBtn}></div>
          </div>
          <AppFooter>
            <Suspense fallback={this.loading()}>
              <FrFooter props={{userInfo: this.state.userInfo}} />
            </Suspense>
          </AppFooter>
        </div>
      :
      <div className="app"> {/* begin of app */}
        {/* <p className="sessionTime">세션 유지 시간(초) : {this.state.checkTime}</p> */}
        <AppHeader fixed>
          <Suspense  fallback={this.loading()}>
            <Header onLogout={e=>this.signOut(e)} props={{userInfo: this.state.userInfo}}/>
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              <AppSidebarNav 
                navConfig={this.state.nav} 
                {...this.props} />
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes}/>
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                          <route.component {...props} />
                        )} />
                    ) : (null);
                  })}
                  <Redirect from="/" to="dashboard" />
                </Switch>
              </Suspense>
            </Container>
          </main>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <Footer />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

export default MainView;
