import Service from "../_service";
import { LOGIN_STATUS_CODE } from "../_constants";

export const getAuthState = function () {
  let isLogined = false;
  let userInfo = {};
  if(localStorage.getItem('isLogined')) {
    if(localStorage.getItem('isLogined')  === 'true') {
      isLogined = true;
    }
  }
  if(localStorage.getItem('userInfo')) {
    userInfo =  JSON.parse(localStorage.getItem('userInfo'));
  }
  return {
    isLogined: isLogined,
    userID: localStorage.getItem('userID'),
    userInfo: userInfo
  }
};

export const login = (username, password) => {
    const url = "admin/auth";
    const params = new URLSearchParams();
    params.append('j_username', username);  
    params.append('j_password', password);
    return Service.http.post_params(url, params).then(function (user) {
      const statusCode = user.data.statusCode;
      if(statusCode === LOGIN_STATUS_CODE.SUCCESSFUL) {
        const userInfo = user.data.data.userInfo;
        localStorage.removeItem('isLogined');
        localStorage.removeItem('userID');
        localStorage.removeItem('userInfo');
        localStorage.setItem('isLogined', true);
        localStorage.setItem('userID', userInfo.username);
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
      } else {
        localStorage.removeItem('isLogined');
        localStorage.removeItem('userID');
        localStorage.removeItem('userInfo');
      }
      return user.data;
    });
}

export const frLogin = (code, username, password) => {
  const url = "fr/auth";
  const params = new URLSearchParams();
  params.append('j_code', code);
  params.append('j_username', username);
  params.append('j_password', password);
  return Service.http.post_params(url, params).then(function (user) {
    const statusCode = user.data.statusCode;
    if(statusCode === LOGIN_STATUS_CODE.SUCCESSFUL) {
      const userInfo = user.data.data.userInfo;
      localStorage.removeItem('isLogined');
      localStorage.removeItem('userID');
      localStorage.removeItem('userInfo');
      localStorage.setItem('isLogined', true);
      localStorage.setItem('userID', userInfo.username);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    } else {
      localStorage.removeItem('isLogined');
      localStorage.removeItem('userID');
      localStorage.removeItem('userInfo');
    }
    return user.data;
  });
}

export const reset = () => {
  localStorage.removeItem('isLogined');
  localStorage.removeItem('userID');
  localStorage.removeItem('userInfo');
  Service.http.post_params("admin/logout", {}).then(function (user) {});
};