import React, { Component } from 'react';
import { Button, Col, Container, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import {
  withRouter
} from 'react-router-dom'

class Page500 extends Component {
  gotoLogin = () => {
    this.props.history.push("/login");
  }
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <span className="clearfix">
                <h1 className="float-left display-3 mr-4">500</h1>
                <h4 className="pt-3">에러가 발생하였습니다!</h4>
                <p className="text-muted float-left">에러가 발생하였습니다. 관리자에게 문의하여 주시기 바랍니다.</p>
              </span>
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend">
                </InputGroupAddon>
                  <Button color="info" onClick={this.gotoLogin}>로그인 페이지로 이동</Button>
              </InputGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default withRouter(Page500);