import React from 'react';
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = function ({props, component: Component, ...rest}, context) {
    return <Route {...rest} render={(matchProps) => 
        (context.user.loggedIn ? <Component {...matchProps} {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />;
}

PrivateRoute.contextTypes = {
    user: PropTypes.object
}

export default PrivateRoute;
