import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types'
import {
  HashRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import './App.scss';
import PrivateRoute from './modules/_routes/PrivateRoute';
import RouteWithProps from './modules/_routes/RouteWithProps';
import Login from "./views/Pages/Login";
import FrLogin from "./views/Pages/FrLogin";
import Page404 from "./views/Pages/Page404";
import Page500 from "./views/Pages/Page500";
import FrPage404 from "./views/Pages/FrPage404";
import FrPage500 from "./views/Pages/FrPage500";
import MainView from "./views/Pages/MainView";

import * as authentication from './modules/_login/authentication';

import { history } from './modules/_helpers';
import { STORE_CONSTANTS, LOGIN_STATUS_CODE } from './modules/_constants';

class App extends Component {

  constructor (props) {
    super(props);
    this.login = this.login.bind(this);
    this._logout = this._logout.bind(this);
    this.frLogin = this.frLogin.bind(this);

    let authState = authentication.getAuthState();

    this.state = {
      user: {
        loggedIn: authState.isLogined,
        userID: authState.userID,
        userInfo: authState.userInfo
      }
    }

    const { dispatch } = this.props;
    history.listen((location, action) => {
        dispatch({type: STORE_CONSTANTS.LOGIN_INFO,
          payload: {
            isAuthenticated: authState.isLogined,
            userID: authState.userID,
            userInfo: authState.userInfo
          }
        });
      });
  }

  login (username, password) {
    const au = authentication.login(username, password, this).then(result => {
          const statusCode = result.statusCode;
          if(statusCode === LOGIN_STATUS_CODE.SUCCESSFUL) {
            const userInfo = result.data.userInfo;
            this.props.dispatch({type: STORE_CONSTANTS.LOGIN_INFO,
              payload: {
                isAuthenticated: true,
                userID: userInfo.username,
                userInfo: userInfo
              }
            });
            this.setState({
              user: {
                loggedIn: true,
                userID: userInfo.username,
                userInfo: userInfo
              }
            });
          } else {
            this.props.dispatch({type: STORE_CONSTANTS.LOGIN_FAILED});
            this.setState({
              user: {
                loggedIn: false,
                userID: null,
                userInfo: {}
              }
            });
          }
          return result;
        }).catch(err => {
          this.props.dispatch({type: STORE_CONSTANTS.LOGIN_FAILED});
          this.setState({
            user: {
              loggedIn: false,
              userID: null,
              userInfo: {}
            }
          });
          return Promise.reject(err);
        });
        return au;
  }

  frLogin (code, username, password) {
    const au = authentication.frLogin(code, username, password, this).then(result => {
          const statusCode = result.statusCode;
          if(statusCode === LOGIN_STATUS_CODE.SUCCESSFUL) {
            const userInfo = result.data.userInfo;
            this.props.dispatch({type: STORE_CONSTANTS.LOGIN_INFO,
              payload: {
                isAuthenticated: true,
                userID: userInfo.username,
                userInfo: userInfo
              }
            });
            this.setState({
              user: {
                loggedIn: true,
                userID: userInfo.username,
                userInfo: userInfo
              }
            });
          } else {
            this.props.dispatch({type: STORE_CONSTANTS.LOGIN_FAILED});
            this.setState({
              user: {
                loggedIn: false,
                userID: null,
                userInfo: {}
              }
            });
          }
          return result;
        }).catch(err => {
          this.props.dispatch({type: STORE_CONSTANTS.LOGIN_FAILED});
          this.setState({
            user: {
              loggedIn: false,
              userID: null,
              userInfo: {}
            }
          });
          return Promise.reject(err);
        });
        return au;
  }

  getChildContext () {
    return {
      user:{
        loggedIn: this.state.user.loggedIn,
        userID: this.state.user.userID,
        userInfo: this.state.user.userInfo
      }
    };
  }

  _logout () {
    this.props.dispatch({type: STORE_CONSTANTS.LOGOUT_SUCCESS});
    this.setState({
      user: {
        loggedIn: false,
        userID: null,
        userInfo: {}
      }
    });
    authentication.reset();
  }

  render() {
    return (
      <Router>
        <Switch>
          <RouteWithProps exact path="/login" component={Login} props={{ login: this.login, logout: this._logout }} />
          <RouteWithProps exact path="/fr/login" component={FrLogin} props={{ frLogin: this.frLogin, logout: this._logout }} />
          <Route          exact path='/error' render={()=> <Page500 />} />
          <Route          exact path='/fr/error' render={()=> <FrPage500 />} />
          <PrivateRoute   path='/' name="Home" component={MainView} props={{userInfo: this.state.user, nav: this.state.user.nav}} />
          <Route          path='*' render={()=> <Page404 />}   />
        </Switch>
      </Router>
    );
  }
}

App.childContextTypes = {
  user: PropTypes.shape({
    loggedIn: PropTypes.bool,
    userID: PropTypes.string,
    userInfo: PropTypes.object
  })
}
const mapStateToProps = user => ({ user });
export default connect(mapStateToProps)(App);
