import axios from 'axios';

const session = {
    sessionTime: 0
}

const LOGIN_STATUS_CODE = {
  SUCCESSFUL: 200,                      // 성공
  UNAUTHORIZED: 401,                    // 인증실패
  UNAUTHENTICATION: 400,                // 인증정보 없음
  AUTHENTICATION_FAILURE: 410,          // 로그인 실패
  ACCESS_FAILURE: 440,                  // 접근권한 없음
  USER_NOT_USED: 406,                   // 유저 사용 불가
  USER_IS_LOCK: 402,                    // 유저 잠금 상태
  USER_IS_NOT_ALLOWS: 405,              // 유저 사용 허용 불가
  USER_IS_LOCKED: 407,                  // 비밀번호 실패로 인한 잠금 상태
  ERROR_IS_DB_FAILED: 511,              // DB 등록,수정,삭제 중 에러
  ERROR_IS_GYM_CODE_FAILED: 501,        // GYM 코드 유효하지 않음
  ERROR_IS_SERVER_ERROR: 500,           // 서버에러
  ERROR_IS_INPUT_PARAMS_INVALID: 503    // 입력 파라메터 오류
}

const HTTP_STATUS_CODE = {
  SUCCESSFUL: 'R200',                     // 성공
  FAILED_IS_USER_DUPLICATED: 'R412',      // 유저 아이디 중복
  ERROR_IS_DB_FAILED: 'R511',             // DB 등록,수정,삭제 중 에러
  ERROR_IS_GYM_CODE_FAILED: 'R501',       // GYM 코드 유효하지 않음
  ERROR_IS_SERVER_ERROR: 'R500',          // 서버에러
  ERROR_IS_INPUT_PARAMS_INVALID: 'R511',  // 입력 파라메터 오류
  ERROR_IS_SERVER_TO_SERVER: 'R504',      // 서버 To 서버 통신 에러
  FAILED_IS_EMAIL_DUPLICATED: 'R410'      // 이메일 중복
}

const instanceLogin = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true
});

const instanceToken = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
  crossDomain: true,
  headers: {
    //'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
  }
});

  // Add a response interceptor
instanceLogin.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    window.location.href = "/#/error";
    return Promise.reject(error);
  });


// Add a response interceptor
instanceToken.interceptors.response.use(function (response) {
  let userSessionTime = 60;
  if(localStorage.getItem('userInfo')) {
    userSessionTime = JSON.parse(localStorage.getItem('userInfo')).sessionIntervalTime;
  }
  session.sessionTime = userSessionTime;
  return response;
}, function (error) {
  const status = error.response.status;
  const path = error.response.data.path;

  let loginPage = "/#/login";
  let errorPage = "/#/error";

  if(path.indexOf("/fr/") > -1){
    loginPage = "/#/fr/login";
    errorPage = "/#/fr/error";
  }
  

  // Do something with response error
  if (!error.response) {
    window.location.href = errorPage;
  }
  
  if (status === LOGIN_STATUS_CODE.UNAUTHORIZED) {
    alert('로그인 정보가 만료되었습니다. 로그인 페이지로 이동하겠습니다.');
    window.location.href = loginPage;
  } else if (status === LOGIN_STATUS_CODE.ERROR_IS_INPUT_PARAMS_INVALID) {
    alert('입력된 파라메터의 검증오류로 인해 로그아웃을 실행합니다. \r\n 관리자에게 문의하여 주시기 바랍니다.');
    window.location.href = errorPage;
  } else if (status === LOGIN_STATUS_CODE.ERROR_IS_DB_FAILED) {
    alert('데이터 입력중 오류가 발생하였습니다. \r\n 관리자에게 문의하여 주시기 바랍니다.');
    window.location.href = errorPage;
  } else {
    alert('서버와의 통신중 에러가 발생하였습니다. \r\n 관리자에게 문의하여 주시기 바랍니다.');
    window.location.href = errorPage;
  }
  return Promise.reject(error);
})

export default {
    session: session,
    http_code: HTTP_STATUS_CODE,
    post_params (url, params) {
      return instanceLogin.post(url, params);
    },
    get (url, params) {
      return instanceToken.get(url, {params: params});
    },
    post_body (url, params) {
      return instanceToken.post(url, params);
    },
    post_multipart (url, formData) {
      return instanceToken.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    },
    patch (url, params) {
      return instanceToken.put(url, params);
    },
    delete (url, params) {
      return instanceToken.delete(url, params);
    }
}
  