import React, { Component } from 'react';
import { Button, Col, Container, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import {
  withRouter
} from 'react-router-dom'

import "./Franchise.css";

class FrPage404 extends Component {
  gotoLogin = () => {
    this.props.history.push("/fr/login");
  }
  render() {
    return (
      <div className="frErrorPage">
        <div className="frErrorPageLogo">
          <img src="/assets/img/nav/nav_logo.svg" alt="유통기한 언제지" />
        </div>
        <div className="frErrorCon">
          <img src="/assets/img/error/img_404.png" alt="404 PAGE NOT FOUND" />
          <div className="frErrorTitle">요청하신 페이지를 찾을 수 없습니다</div>
          <div className="frErrorDesc">
            삭제되었거나 주소가 잘못되어 찾을 수 없는 페이지입니다.<br />
            입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요.
          </div>
          <Button onClick={this.gotoLogin}>로그인 홈으로 가기</Button>
        </div>
      </div>
    );
  }
}
export default withRouter(FrPage404);