import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert  } from 'reactstrap';
import styles from './AlertComponent.css'; // Import css modules stylesheet as styles
class AlertComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            color: 'success',
            header: '알림',
            desc: ''
        };
    }

    show = (color, desc) => {
        let header = this.state.header;

        if(color === 'danger') {
            header = '위험';
        } else if (color === 'warning') {
            header = '경고';
        } else if (color === 'success') {
            header = '알림';
        }

        this.setState({isOpen:true, color: color, header: header, desc: desc}, () => {
            window.setTimeout(() => {
                this.setState({isOpen:false})
            }, 2000)
        });
    }
    render() {
        return (
            <Alert color={this.state.color} isOpen={this.state.isOpen} className={styles.top}>
                <h5 className="alert-heading">{this.state.header}</h5>
                <hr />
                <p className="mb-0">
                    <b>{this.state.desc}</b>
                </p>
                
            </Alert>
        )
    }
}

AlertComponent.propTypes = {
    color: PropTypes.string, // default: 'success'
    isOpen: PropTypes.bool,  // default: true
    desc: PropTypes.string
}

export default AlertComponent;