import React from 'react';

const Dashboard = React.lazy(() => import('../../views/Pages/dashboard/Dashboard'));

const ClientManagement = React.lazy(() => import('../../views/Pages/client/ClientManagement'));

const StockManagement = React.lazy(() => import('../../views/Pages/stock/StockManagement'));

const RnFManagement = React.lazy(() => import('../../views/Pages/stock/RnFManagement'));
const ReceivingManagement = React.lazy(() => import('../../views/Pages/stock/ReceivingManagement'));
const ForwardingManagement = React.lazy(() => import('../../views/Pages/stock/ForwardingManagement'));
const LossManagement = React.lazy(() => import('../../views/Pages/stock/LossManagement'));

const ShoppingManagement = React.lazy(() => import('../../views/Pages/shopping/ShoppingManagement'));
const ShoppingCreate = React.lazy(() => import('../../views/Pages/shopping/ShoppingCreate'));

const OrderManagement = React.lazy(() => import('../../views/Pages/order/OrderManagement'));
const OrderCreate = React.lazy(() => import('../../views/Pages/order/OrderCreate'));

const CancelManagement = React.lazy(() => import('../../views/Pages/store/CancelManagement'));
const CancelCreate = React.lazy(() => import('../../views/Pages/store/CancelCreate'));
const RefundManagement = React.lazy(() => import('../../views/Pages/store/RefundManagement'));
const RefundCreate = React.lazy(() => import('../../views/Pages/store/RefundCreate'));
const ExchangeManagement = React.lazy(() => import('../../views/Pages/store/ExchangeManagement'));
const ExchangeCreate = React.lazy(() => import('../../views/Pages/store/ExchangeCreate'));

const SellManagement = React.lazy(() => import('../../views/Pages/sell/SellManagement'));

const HelpManagement = React.lazy(() => import('../../views/Pages/help/HelpManagement'));

const ReviewDistManagement = React.lazy(() => import('../../views/Pages/review_distributor/ReviewDistManagement'));

const ProfileManagement = React.lazy(() => import('../../views/Pages/profile/ProfileManagement'))



// 기존 관리자 페이지
const ApiUserManagement = React.lazy(() => import('../../views/Pages/user/UserManagement'));
const ApiUserDetail = React.lazy(() => import('../../views/Pages/user/UserDetail'));

const FridgeManagement = React.lazy(() => import('../../views/Pages/user/FridgeManagement'));
const FridgeDetail = React.lazy(() => import('../../views/Pages/user/FridgeDetail'));
const PointManagement = React.lazy(() => import('../../views/Pages/user/PointManagement'));
const MemberDetail = React.lazy(() => import('../../views/Pages/user/MemberDetail'));

const FoodsManagement = React.lazy(() => import('../../views/Pages/foods/FoodsManagement'));
const UserDBManagement = React.lazy(() => import('../../views/Pages/foods/UserDBManagement'));
const CategoryManagement = React.lazy(() => import('../../views/Pages/foods/CategoryManagement'));
const CheckOCR = React.lazy(() => import('../../views/Pages/foods/CheckOCR'));

const ProductManagement = React.lazy(() => import('../../views/Pages/product/ProductManagement'));
const FreeManagement = React.lazy(() => import('../../views/Pages/product/FreeManagement'));
const ProductDetail = React.lazy(() => import('../../views/Pages/product/ProductDetail'));
const FreeDetail = React.lazy(() => import('../../views/Pages/product/FreeDetail'));
const FoodProductManagement = React.lazy(() => import('../../views/Pages/product/FoodProductManagement'));


const AdminManagement = React.lazy(() => import('../../views/Pages/admin/AdminManagement'));
const UserManagement = React.lazy(() => import('../../views/Pages/admin/UserManagement'));
const AdminPointManagement = React.lazy(() => import('../../views/Pages/admin/AdminPointManagement'));
const NoticeManagement = React.lazy(() => import('../../views/Pages/admin/NoticeManagement'));
const NoticeEditor = React.lazy(() => import('../../views/Pages/admin/NoticeEditor'));

// 유통업체 관리
const DistributorManagement = React.lazy(() => import('../../views/Pages/dist/DistributorManagement'));

// 위생관리자 관리
const SupervisorManagement = React.lazy(() => import('../../views/Pages/supervisor/SupervisorManagement'));

const RouteManagement = React.lazy(() => import('../../views/Pages/route/RouteManagement'));

const Settings = React.lazy(() => import('../../views/Pages/Settings'));

// 자주 하는 질문 관리
const FaqManagement = React.lazy(() => import('../../views/Pages/faq/FaqManagement'));

// 마케팅 푸시 관리
const PushManagement = React.lazy(() => import('../../views/Pages/push/PushManagement'));

// 관리자 통합 리뷰 관리
const ReviewManagement = React.lazy(() => import('../../views/Pages/review/ReviewManagement'));

// 정산 관리(임시)
const SettleManagement = React.lazy(() => import('../../views/Pages/settle/SettleManagement'));


// 재고 관리 서비스(본사)
const FrDashboard = React.lazy(() => import('../../views/Pages/franchise/dashboard/FrDashboard'));
const FranchiseManagement = React.lazy(() => import('../../views/Pages/franchise/franchise/FranchiseManagement'));
const FrFridgeManagement = React.lazy(() => import('../../views/Pages/franchise/fridge/FrFridgeManagement'));
const FrFridgeDetail = React.lazy(() => import('../../views/Pages/franchise/fridge/FrFridgeDetail'));
const FrTotalFridgeDetail = React.lazy(() => import('../../views/Pages/franchise/fridge/FrTotalFridgeDetail'));
const FrMoveFoodsManagement = React.lazy(() => import('../../views/Pages/franchise/foods/FrMoveFoodsManagement'));
const FrHistoryManagement = React.lazy(() => import('../../views/Pages/franchise/history/FrHistoryManagement'));
const FrFoodsManagement = React.lazy(() => import('../../views/Pages/franchise/foods/FrFoodsManagement'));
const FrOrderManagement = React.lazy(() => import('../../views/Pages/franchise/order/FrOrderManagement'));
const FrOrderDetail = React.lazy(() => import('../../views/Pages/franchise/order/FrOrderDetail'));
const FrClientManagement = React.lazy(() => import('../../views/Pages/franchise/client/FrClientManagement'));
const FrInspectionManagement = React.lazy(() => import('../../views/Pages/franchise/supervisor/FrInspectionManagement'));
const FrInspectionDetail = React.lazy(() => import('../../views/Pages/franchise/supervisor/FrInspectionDetail'));
const FrHygieneManagement = React.lazy(() => import('../../views/Pages/franchise/supervisor/FrHygieneManagement'));
const FrNoticeManagement = React.lazy(() => import('../../views/Pages/franchise/supervisor/FrNoticeManagement'));
const FrProfileManagement = React.lazy(() => import('../../views/Pages/franchise/profile/FrProfileManagement'));
const FrMoveHistoryManagement = React.lazy(() => import('../../views/Pages/franchise/moveHistory/FrMoveHistoryManagement'));
const FrExpManagement = React.lazy(() => import('../../views/Pages/franchise/expAndSafety/FrExpManagement'));
const FrSafetyManagement = React.lazy(() => import('../../views/Pages/franchise/expAndSafety/FrSafetyManagement'));

// 재고 관리 서비스(가맹점)
const FrsFridgeManagement = React.lazy(() => import('../../views/Pages/franchise/fridge/FrsFridgeManagement'));
const FrsFridgeDetail = React.lazy(() => import('../../views/Pages/franchise/fridge/FrsFridgeDetail'));
const FrsTotalFridgeDetail = React.lazy(() => import('../../views/Pages/franchise/fridge/FrsTotalFridgeDetail'));
const FrsFoodsManagement = React.lazy(() => import('../../views/Pages/franchise/foods/FrsFoodsManagement'));
const FrsFridgeSettingManagement = React.lazy(() => import('../../views/Pages/franchise/fridgeSetting/FrsFridgeSettingManagement'));
const FrsMoveFoodsManagement = React.lazy(() => import('../../views/Pages/franchise/foods/FrsMoveFoodsManagement'));
const FrsHistoryManagement = React.lazy(() => import('../../views/Pages/franchise/history/FrsHistoryManagement'));
const FrsOrderRequest = React.lazy(() => import('../../views/Pages/franchise/order/FrsOrderRequest'));
const FrsOrderManagement = React.lazy(() => import('../../views/Pages/franchise/order/FrsOrderManagement'));
const FrsOrderDetail = React.lazy(() => import('../../views/Pages/franchise/order/FrsOrderDetail'));
const FrsInspectionManagement = React.lazy(() => import('../../views/Pages/franchise/supervisor/FrsInspectionManagement'));
const FrsInspectionDetail = React.lazy(() => import('../../views/Pages/franchise/supervisor/FrsInspectionDetail'));
const FrsMemberManagement = React.lazy(() => import('../../views/Pages/franchise/member/FrsMemberManagement'));

// 가맹점 유통기한 입력
const FrProductManagement = React.lazy(() => import('../../views/Pages/franchise/product/FrProductManagement'));
const FrProductDetail = React.lazy(() => import('../../views/Pages/franchise/product/FrProductDetail'));

// 재고 관리 서비스 - 방문 관리 서비스(위생관리자)
const SVFranchiseManagement = React.lazy(() => import('../../views/Pages/franchise/supervisor/SVFranchiseManagement'));
const SVFranchiseDetail = React.lazy(() => import('../../views/Pages/franchise/supervisor/SVFranchiseDetail'));
const SVFrHygieneManagement = React.lazy(() => import('../../views/Pages/franchise/supervisor/SVFrHygieneManagement'));

// 미리 소식(관리자)
const MiriNoticeManagement = React.lazy(() => import('../../views/Pages/miri/MiriNoticeManagement'));
const MiriNoticeEditor = React.lazy(() => import('../../views/Pages/miri/MiriNoticeEditor'));

// 배너 관리
const BannerManagement = React.lazy(() => import('../../views/Pages/banner/BannerManagement'));
const BannerEditor = React.lazy(() => import('../../views/Pages/banner/BannerEditor'));


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  // 입점업체 관리자 페이지
  { path: '/dashboard',  exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/client', exact: true, name: 'Client', component: ClientManagement },
  { path: '/stock', exact: true, name: 'Stock', component: StockManagement },
  { path: '/RnF', exact: true, name: 'RnF', component: RnFManagement },
  { path: '/RnF/RnFManagement', name: 'RnFManagement', component: RnFManagement },
  { path: '/RnF/receiving', name: 'Receiving', component: ReceivingManagement },
  { path: '/RnF/forwarding', name: 'Forwarding', component: ForwardingManagement },
  { path: '/RnF/loss', name: 'Loss', component: LossManagement },
  { path: '/shopping', exact: true, name: 'Shopping', component: ShoppingManagement },
  { path: '/shopping/create', name: 'Shopping Create', component: ShoppingCreate },
  { path: '/shopping/:id', name: 'Shopping Detail', component: ShoppingCreate },
  { path: '/order', exact: true, name: 'Order', component: OrderManagement },
  { path: '/order/:id', name: 'Order Detail', component: OrderCreate },
  { path: '/store', exact: true, name: 'Store', component: CancelManagement },
  { path: '/store/cancel', exact: true, name: 'Cancel', component: CancelManagement },
  { path: '/store/cancel/:id', name: 'Cancel Detail', component: CancelCreate },
  { path: '/store/refund', exact: true, name: 'Refund', component: RefundManagement },
  { path: '/store/refund/:id', name: 'Refund Detail', component: RefundCreate },
  { path: '/store/exchange', exact: true, name: 'Exchange', component: ExchangeManagement },
  { path: '/store/exchange/:id', name: 'Exchange Detail', component: ExchangeCreate },
  { path: '/sell', exact: true, name: 'Sell', component: SellManagement },
  { path: '/help', exact: true, name: 'Help', component: HelpManagement },
  { path: '/reviewDist', exact: true, name: 'ReviewDist', component: ReviewDistManagement },
  { path: '/profile',  exact: true, name: 'Profile Management', component: ProfileManagement },


  // 기존 관리자 페이지
  { path: '/user',  exact: true, name: 'User', component: ApiUserManagement },
  { path: '/user/userManagement', name: 'User Management', component: ApiUserManagement },
  { path: '/user/fridgeManagement', exact:true, name: 'Fridge Management', component: FridgeManagement },
  { path: '/user/fridgeManagement/:id', name: 'Fridge Detail', component: FridgeDetail },
  { path: '/user/pointManagement', name: 'Point Management', component: PointManagement },
  { path: '/user/member/:id', name: 'Member Detail', component: MemberDetail },
  { path: '/user/:id',  name: 'User Detail', component: ApiUserDetail },
  { path: '/foods',  exact: true, name: 'Foods', component: FoodsManagement },
  { path: '/foods/foodsManagement', name: 'Foods Management', component: FoodsManagement },
  { path: '/foods/DBManagement', name: 'DB Management', component: UserDBManagement },
  { path: '/foods/categoryManagement', name: 'Category Management', component: CategoryManagement },
  { path: '/foods/checkOCR', name: 'Check OCR', component: CheckOCR },
  { path: '/product',   exact: true, name: 'Product', component: ProductManagement },
  { path: '/product/productManagement',  name: 'Product Management', component: ProductManagement },
  { path: '/product/freeManagement',  name: 'Free Management', component: FreeManagement },
  { path: '/product/foodProductManagement',  name: 'Food Product Management', component: FoodProductManagement },
  { path: '/product/detail/paid/:id',  name: 'Product Detail', component: ProductDetail },
  { path: '/product/detail/free/:id',  name: 'Free Detail', component: FreeDetail },
  { path: '/admin/superManagement',  name: 'Super Admin', component: AdminManagement },
  { path: '/admin/adminManagement',  name: 'User Admin', component: UserManagement },
  { path: '/admin/adminPointManagement/:id',  name: 'Admin Point', component: AdminPointManagement },
  { path: '/admin/noticeManagement', exact: true, name: 'Notice', component: NoticeManagement },
  { path: '/admin/noticeManagement/editor', exact: true, name: 'Editor(등록)', component: NoticeEditor },
  { path: '/admin/noticeManagement/:id', exact: true, name: 'Editor(수정)', component: NoticeEditor },
  { path: '/settings',   exact: true, name: 'settings', component: Settings },
  { path: '/route',  exact: true, name: 'Access Route Management', component: RouteManagement },
  { path: '/dist',  exact: true, name: 'Distributor Management', component: DistributorManagement },
  { path: '/supervisor',  exact: true, name: 'Supervisor Management', component: SupervisorManagement },
  { path: '/faq',  exact: true, name: 'Faq Management', component: FaqManagement },
  { path: '/push',  exact: true, name: 'Push Management', component: PushManagement },
  { path: '/review',  exact: true, name: 'Review Management', component: ReviewManagement },

  // 정산 관리(임시)
  { path: '/settle',  exact: true, name: 'Settle Management', component: SettleManagement },

  // 재고 관리 서비스(본사)
  { path: '/fr/dashboard', exact: true, name: 'Dashboard', component: FrDashboard },
  { path: '/fr/store/franchise', exact: true, name: 'Franchise Management', component: FranchiseManagement },
  { path: '/fr/store/fridge', exact: true, name: 'Fridge Management', component: FrFridgeManagement },
  { path: '/fr/store/fridge/:id', name: 'Fridge Detail', component: FrFridgeDetail },
  { path: '/fr/total/hq/fridge', name: 'Total Fridge Detail', component: FrTotalFridgeDetail },
  { path: '/fr/store/food', exact: true, name: 'Move Foods Management', component: FrMoveFoodsManagement },
  { path: '/fr/store/history', exact: true, name: 'History Management', component: FrHistoryManagement },
  { path: '/fr/foods', exact: true, name: 'Foods Management', component: FrFoodsManagement },
  { path: '/fr/order/order', exact: true, name: 'Order Management', component: FrOrderManagement },
  { path: '/fr/order/order/:id', exact: true, name: 'Order Detail', component: FrOrderDetail },
  { path: '/fr/order/client', exact: true, name: 'Client Management', component: FrClientManagement },
  { path: '/fr/sv/inspection', exact: true, name: 'Inspection Management', component: FrInspectionManagement },
  { path: '/fr/sv/inspection/:id', exact: true, name: 'Inspection Detail', component: FrInspectionDetail },
  { path: '/fr/sv/hygiene', exact: true, name: 'Hygiene Management', component: FrHygieneManagement },
  { path: '/fr/sv/notice', exact: true, name: 'Notice Management', component: FrNoticeManagement },
  { path: '/fr/profile', exact: true, name: 'Profile', component: FrProfileManagement },
  { path: '/fr/store/move/history', exact: true, name: 'Move History Management', component: FrMoveHistoryManagement },
  { path: '/fr/exp', exact: true, name: 'Exp Management', component: FrExpManagement },
  { path: '/fr/safety', exact: true, name: 'Safety Management', component: FrSafetyManagement },

  // 재고 관리 서비스(가맹점)
  { path: '/fr/food/fridge', exact: true, name: 'Fridge Management', component: FrsFridgeManagement },
  { path: '/fr/food/fridge/:id', name: 'Fridge Detail', component: FrsFridgeDetail },
  { path: '/fr/total/fridge', name: 'Total Fridge Detail', component: FrsTotalFridgeDetail },
  { path: '/fr/food/manage', exact: true, name: 'Foods Management', component: FrsFoodsManagement },
  { path: '/fr/food/setting', exact: true, name: 'Fridge Setting Management', component: FrsFridgeSettingManagement },
  { path: '/fr/food/move', exact: true, name: 'Move Foods Management', component: FrsMoveFoodsManagement },
  { path: '/fr/food/history', exact: true, name: 'History Management', component: FrsHistoryManagement },
  { path: '/fr/order/request', exact: true, name: 'Order Request', component: FrsOrderRequest },
  { path: '/fr/order/status', exact: true, name: 'Order Management', component: FrsOrderManagement },
  { path: '/fr/order/status/:id', exact: true, name: 'Order Detail', component: FrsOrderDetail },
  { path: '/fr/inspection', exact: true, name: 'Inspection Management', component: FrsInspectionManagement },
  { path: '/fr/inspection/:id', exact: true, name: 'Inspection Detail', component: FrsInspectionDetail },
  { path: '/fr/member',  exact: true, name: 'Member', component: FrsMemberManagement },

  // 가맹점 유통기한 등록
  { path: '/fr/product/productManagement',  exact: true, name: 'Fr ProductManagement', component: FrProductManagement },
  { path: '/fr/product/detail/:id',  name: 'Product Detail', component: FrProductDetail },

  // 재고 관리 서비스 - 방문 관리 서비스(위생관리자)
  { path: '/fr/supervisor/franchise', exact: true, name: 'Franchise Management', component: SVFranchiseManagement },
  { path: '/fr/supervisor/franchise/:id', name: 'Franchise Detail', component: SVFranchiseDetail },
  { path: '/fr/supervisor/hygiene/:id', exact: true, name: 'Hygiene Management', component: SVFrHygieneManagement },

  // 미리 소식(관리자)
  { path: '/miri/notice', exact: true, name: 'Miri Notice', component: MiriNoticeManagement },
  { path: '/miri/notice/editor', exact: true, name: 'Miri Editor(등록)', component: MiriNoticeEditor },
  { path: '/miri/notice/:id', exact: true, name: 'Miri Editor(수정)', component: MiriNoticeEditor },

  // 배너 관리
  { path: '/banner', exact: true, name: 'Banner Management', component: BannerManagement },
  { path: '/banner/editor', exact: true, name: 'Editor(등록)', component: BannerEditor },
  { path: '/banner/:id', exact: true, name: 'Editor(수정)', component: BannerEditor },
];

export default routes;
