import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import "./polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from "redux";
import { Provider } from "react-redux";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { STORE_CONSTANTS } from './modules/_constants';

const reducer = (
    state = {
      isAuthenticated: false,
      userID: null,
      userInfo: {}
    },
    action
  ) => {
    switch (action.type) {
      case STORE_CONSTANTS.LOGIN_SUCCESS:
        return Object.assign({}, 
            { 
                isAuthenticated: true,
                userID: action.payload.userID,
                userInfo: action.payload.userInfo
            });
      case STORE_CONSTANTS.LOGOUT_SUCCESS:
        return Object.assign({}, 
            { 
                isAuthenticated: false,
                userID: null,
                userInfo: {}
            });
      case STORE_CONSTANTS.LOGIN_FAILED:
        return Object.assign({},
          {
            isAuthenticated: false,
            userID: null,
            userInfo: {}
          }
        );
      case STORE_CONSTANTS.LOGIN_INFO:
        return Object.assign({},
          {
            isAuthenticated: action.payload.isAuthenticated,
            userID: action.payload.userID,
            userInfo: action.payload.userInfo
          }
        );  
      default:
        return state;
    }
  };
  
const store = createStore(reducer);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
