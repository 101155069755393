import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { AvForm, AvInput} from 'availity-reactstrap-validation';
import { Button, Card, CardBody, CardGroup, Col, Container, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import AlertComponent from "../../modules/_elements/AlertComponent";
import { LOGIN_STATUS_CODE } from '../../modules/_constants';

class Login extends Component {
  constructor (props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      j_username: '',
      j_password: '',
      isOpen: false, 
      isDisabled: false
    }
    this.alert = React.createRef();
  }

  componentDidMount() {
    this.props.logout();

    document.querySelector("title").innerText = "유통기한 언제지 관리자 페이지";
  }
  
  handleSubmit(event, values) {
    this.setState({ isDisabled: true });
    const context = this;
    const username = values.j_username;
    const password = values.j_password;

    return this.props.login(username, password).then(function (response) { 
      const statusCode = response.statusCode;
      if(statusCode === LOGIN_STATUS_CODE.SUCCESSFUL) {
        context.props.history.push("/dashboard");
      } else if (statusCode === LOGIN_STATUS_CODE.USER_IS_LOCKED) {
        context.alert.current.show("warning", '비밀번호 실패 횟수 초과로 인한 유저 잠금 \n(관리자에게 문의하시기 바랍니다.)');
        context.setState({
          j_password: '',
          isDisabled: false 
        });
      } else if (statusCode === LOGIN_STATUS_CODE.UNAUTHENTICATION) {
        context.alert.current.show("warning", '인증 정보가 없습니다. \n(관리자에게 문의하시기 바랍니다.)');
        context.setState({
          j_password: '',
          isDisabled: false 
        });
      } else if (statusCode === LOGIN_STATUS_CODE.USER_NOT_USED) {
        context.alert.current.show("warning", '유저의 사용이 제한되었습니다. \n(관리자에게 문의하시기 바랍니다.)');
        context.setState({
          j_password: '',
          isDisabled: false 
        });
      } else {
        context.alert.current.show("warning", '로그인에 실패하였습니다. \n(관리자에게 문의하시기 바랍니다.)');
        context.setState({
          j_password: '',
          isDisabled: false 
        });
      }
     });
  }

  handleChange = (e) => {
    const target = e.target;
    this.setState({
        [target.name]: target.value
    });
    
  }

  handleInvalidSubmit = (event, errors, values) => {
    this.alert.current.show("warning", '유저아이디와 비밀번호는 빈값이 될수 없습니다.');
    this.setState({ isDisabled: false });
  }
 
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
        <AlertComponent ref={this.alert} />
          <Row className="justify-content-center">
            <Col md="8">
              <LoadingOverlay active={this.state.isDisabled} spinner text='데이터 로딩중입니다....'>
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <AvForm name="form" onValidSubmit={this.handleSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
                      <h1>로그인</h1>
                      <p className="text-muted">계정정보를 입력하여 주세요.</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput type="text" placeholder="유저 아이디" autoComplete="j_username" name="j_username"  value={this.state.j_username} onChange={e => this.handleChange(e)} validate={{required: {value: true}}} />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput type="password" placeholder="비밀번호" autoComplete="current-password" name="j_password" value={this.state.j_password}  onChange={e => this.handleChange(e)} validate={{required: {value: true}}}   />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4" disabled={this.state.isEnabled}>Login</Button>
                        </Col>
                        <Col xs="6" className="text-right"></Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </CardGroup>
              </LoadingOverlay>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Login.contextTypes = {
  auth: PropTypes.object
}

Login.propTypes = {
  login: PropTypes.func
}


const mapStateToProps = user => ({ user });
export default connect(mapStateToProps)(Login);