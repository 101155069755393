import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { AvForm, AvInput} from 'availity-reactstrap-validation';
import { Button, Col, Row, FormGroup, Label } from 'reactstrap';
import AlertComponent from "../../modules/_elements/AlertComponent";
import { LOGIN_STATUS_CODE } from '../../modules/_constants';

class FrLogin extends Component {
  constructor (props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      j_username: '',
      j_password: '',
      isNotReady: true,
    }
    this.alert = React.createRef();
  }
  componentDidMount() {

    document.querySelector("title").innerText = "유통기한 언제지 재고 관리 서비스";
    
    // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // if(userInfo == null) {
    //   this.props.logout();
    //   document.getElementById("j_code").focus();
    // }else{
    //   this.props.history.push("/fr/dashboard");
    // }
  }
  
  handleSubmit(event, values) {
    const context = this;
    const code = values.j_code;
    const username = values.j_username;
    const password = values.j_password;

    return this.props.frLogin(code, username, password).then(function (response) { 
      const statusCode = response.statusCode;
      if(statusCode === LOGIN_STATUS_CODE.SUCCESSFUL) {
        context.props.history.push("/fr/dashboard");
      } else if (statusCode === LOGIN_STATUS_CODE.USER_IS_LOCKED) {
        context.alert.current.show("warning", '비밀번호 실패 횟수 초과로 인한 유저 잠금 \n(관리자에게 문의하시기 바랍니다.)');
        context.setState({
          j_password: '',
        });
      } else if (statusCode === LOGIN_STATUS_CODE.UNAUTHENTICATION) {
        context.alert.current.show("warning", '인증 정보가 없습니다. \n(관리자에게 문의하시기 바랍니다.)');
        context.setState({ 
          j_password: '',
        });
      } else if (statusCode === LOGIN_STATUS_CODE.USER_NOT_USED) {
        context.alert.current.show("warning", '유저의 사용이 제한되었습니다. \n(관리자에게 문의하시기 바랍니다.)');
        context.setState({
          j_password: '',
        });
      } else {
        context.alert.current.show("warning", '계정 정보가 맞지 않습니다. \n입력 후 다시 시도해주세요.');
        context.setState({
          j_password: '',
        });
      }
     });
  }

  handleChange = (e) => {
    const target = e.target;
    const username = document.getElementById('j_username').value;
    const password = document.getElementById('j_password').value;
    let isNotReady = true;

    if( (username !== '') && (password !== '') ) {
      isNotReady = false;
    }

    this.setState({
        [target.name]: target.value,
        isNotReady
    });
    
  }

  handleInvalidSubmit = (event, errors, values) => {
    this.alert.current.show("warning", '계정 정보를 모두 입력해주세요.');
  }
 
  render() {
    return (
      <div className="app flex-row align-items-center frLogin">
        <AlertComponent ref={this.alert} />
        <div className="frLoginBox">
          <div className="frLoginTitle">
            <img src={'/miri-logo-vertical-green.svg'} alt="MIRI logo" />
            <div className="frLoginDesc">
              가맹점 재고 관리 페이지는<br />
              발급 받은 계정이 있을 경우에만 사용이 가능합니다.
            </div>
          </div>
          <AvForm name="form" onValidSubmit={this.handleSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
            <div className="frModalForm">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label for="j_username">아이디 (이메일)</Label>
                    <AvInput type="text" name="j_username" id="j_username" value={this.state.j_username} onChange={e => this.handleChange(e)} 
                    validate={{required: {value: true}}} placeholder="ex. needs12@needs.com" />
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label for="j_password">
                      비밀번호
                    </Label>
                    <AvInput type="password" name="j_password" id="j_password" value={this.state.j_password} onChange={e => this.handleChange(e)} 
                    validate={{required: {value: true}}} placeholder="영문, 숫자를 포함하여 8글자로 입력해주세요" />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <Button className="frGreenButton" disabled={this.state.isNotReady}>로그인</Button>
          </AvForm>
          <a href="https://dd73q.channel.io" target="_blank" className="channelTalk" rel="noopener noreferrer">문제가 있으신가요?</a>
        </div>
      </div>
    );
  }
}

FrLogin.contextTypes = {
  auth: PropTypes.object
}

FrLogin.propTypes = {
  login: PropTypes.func
}


const mapStateToProps = user => ({ user });
export default connect(mapStateToProps)(FrLogin);